import React, { useContext } from 'react';
import { Form, Checkbox } from 'antd';
import { useSessionFormData } from '../globalContext';

const SettingsMusic = () => {
  const { sessionFormData, setSessionFormData } = useSessionFormData();

  const handleFormChange = (changedValues, allValues) => {
    console.log(changedValues);
    setSessionFormData(prevData => {
      const updatedMusicSettings = {
        slow_music: allValues.music?.slow_music ?? prevData.settings.music?.slow_music,
        energy_music: allValues.music?.energy_music ?? prevData.settings.music?.energy_music,
        hardcord_music: allValues.music?.hardcord_music ?? prevData.settings.music?.hardcord_music,
      };
      return {
        ...prevData,
        settings: {
          ...prevData.settings,
          music: updatedMusicSettings
        }
      };
    });
  };

  return (
    <Form onValuesChange={handleFormChange}>
      <div className="music-container">
        <h2>Музыка</h2>
        <Form.Item initialValue={sessionFormData.settings?.music?.slow_music ? sessionFormData.settings?.music?.slow_music : false} name={['music', 'slow_music']} valuePropName="checked">
          <Checkbox id="slow_music">Релакс</Checkbox>
        </Form.Item>
        <Form.Item initialValue={sessionFormData.settings?.music?.energy_music ? sessionFormData.settings?.music?.energy_music : false} name={['music', 'energy_music']} valuePropName="checked">
          <Checkbox id="energy_music">Энергичная</Checkbox>
        </Form.Item>
        <Form.Item initialValue={sessionFormData.settings?.music?.hardcord_music ? sessionFormData.settings?.music?.hardcord_music : false} name={['music', 'hardcord_music']} valuePropName="checked">
          <Checkbox id="hardcord_music">Экстрим</Checkbox>
        </Form.Item>
      </div>
    </Form>
  );
};

export default SettingsMusic;