import React, { useState } from 'react';
import { Form, Input, Button, Avatar, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useSessionFormData } from '../globalContext';

const Login = () => {
  const navigate = useNavigate();
  const { sessionFormData, login, setSessionFormData } = useSessionFormData();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const setDataFromDB = () => {
    try {
      const userId = localStorage.getItem('userId');
      const authToken = localStorage.getItem('token');
      fetch(process.env.REACT_APP_API_URL + '/settings/get-settings', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({ email: userId }),
      }).then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        response.json().then(data => {
          if (!data.error) {
            const parsedData = JSON.parse(data);
            setSessionFormData(prevState => {
              const generalSettings = {
                timezone: parsedData?.model?.timezone ?? prevState?.settings?.general?.timezone,
                station_logo: parsedData?.model?.logo ?? prevState?.settings?.general?.station_logo,
                station_name: parsedData?.model?.station_name ?? prevState?.settings?.general?.station_name,
                station_description: parsedData?.model?.station_description ?? prevState?.settings?.general?.station_description,
              };

              const musicSettings = {
                slow_music: parsedData?.model?.slow_music ?? prevState?.settings?.music?.slow_music,
                energy_music: parsedData?.model?.energy_music ?? prevState?.settings?.music?.energy_music,
                hardcord_music: parsedData?.model?.hardcord_music ?? prevState?.settings?.music?.hardcord_music,
              };

              const jingleSettings = {
                use_jingle: parsedData?.model?.enable_jingle ?? prevState?.settings?.jingle?.use_jingle,
                use_time_jingle: parsedData?.model?.enable_time ?? prevState?.settings?.jingle?.use_time_jingle,
                user_time_jingle: parsedData?.model?.user_time_jingle ?? prevState?.settings?.jingle?.user_time_jingle,
              };

              const othersSettings = {
                frequency: parsedData?.model?.news_insert_frequency ?? prevState?.settings?.others?.frequency,
                voices: {
                  rss_voice: parsedData?.model?.rss_voice ?? prevState?.settings?.others?.voices?.rss_voice,
                  gpt_voice: parsedData?.model?.gpt_voice ?? prevState?.settings?.others?.voices?.gpt_voice,
                  jingle_voice: parsedData?.model?.jingle_voice ?? prevState?.settings?.others?.voices?.jingle_voice,
                },
              };

              return {
                ...prevState,
                settings: {
                  general: generalSettings,
                  music: musicSettings,
                  jingle: jingleSettings,
                  others: othersSettings,
                },
              };
            });
          }
        });
      });
    } catch (error) {
      console.error('Error during fetching data from DB:', error);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await fetch(process.env.REACT_APP_API_URL + '/site/login', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(values),
      });

      const data = await response.json();

      if (data.success) {
        localStorage.setItem('role', data.role);
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("userId", values.email);
        if (data.role === "Admin") {
          setDataFromDB();
          localStorage.setItem('stationName', data.station_name);
          login();
          navigate('/neuro');
          window.location.reload(false);
        } else if (data.role === "Superadmin") {
          login();
          navigate('/admin/admin');
          window.location.reload(false);
        }
      } else {
        // Display error in form
        form.setFields([
          {
            name: 'email',
            errors: data.errors?.email ? [data.errors.email] : [],
          },
          {
            name: 'password',
            errors: data.errors?.password ? [data.errors.password] : [],
          },
        ]);
        message.error('Неверный email или пароль');
      }
    } catch (error) {
      console.error('Error during login:', error);
      message.error('Произошла ошибка. Попробуйте снова.');
    } finally {
      setLoading(false);
    }
  };

  const logoPath = '/224.png';
  return (
    <div className="site-login" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Form
        form={form}
        name="login-form"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ width: 'auto', minHeight: '100%' }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Avatar src={logoPath} size={256} style={{ marginBottom: '24px', backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center', minHeight: '100%' }} />
        </div>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Пожалуйста, введите ваш email!' }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
            autoFocus
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Пожалуйста, введите ваш пароль!' }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Пароль"
          />
        </Form.Item>

        <div className='submit-button-login' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
              Войти
            </Button>
          </Form.Item>
        </div>

        <Form.Item style={{ textAlign: 'center' }}>
          <Link to="/site/signup">Зарегистрироваться</Link>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
