import React, {useContext, useEffect, useState} from 'react';
import { Form, Button, Row, Col, Flex, Tabs, message } from 'antd';
import SettingsJingle from './settingsJingle';
import SettingsGeneral from './settingsGeneral';
import SettingsMusic from './settingsMusic';
import SettingsOthers from './settingsOthers';
import { useSessionFormData } from '../globalContext';

const { TabPane } = Tabs;
let authToken = localStorage.getItem('token');
export { authToken };

const SettingsForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  
  const { sessionFormData, setSessionFormData } = useSessionFormData();

  const setDataFromDB = () => {
    try{
      const userId = localStorage.getItem('userId');
      const authToken = localStorage.getItem('token');
      fetch(process.env.REACT_APP_API_URL + '/settings/get-settings', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({email: userId})
      }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      response.json().then(data => {
        if(!data.error){
        const parsedData = JSON.parse(data);
        console.log(parsedData);
        console.log(parsedData.model);
        console.log(data.model);
        setSessionFormData(prevState => {
          const generalSettings = {
            timezone: parsedData?.model?.timezone ?? prevState?.settings?.general?.timezone,
            timezone_name: parsedData?.model?.timezone_name ?? prevState?.settings?.general?.timezone_name,
            station_logo: parsedData?.model?.logo ?? prevState?.settings?.general?.station_logo,
            station_name: parsedData?.model?.station_name ?? prevState?.settings?.general?.station_name,
            station_description: parsedData?.model?.station_description ?? prevState?.settings?.general?.station_description
          };
  
          const musicSettings = {
            slow_music: parsedData?.model?.slow_music ?? prevState?.settings?.music?.slow_music,
            energy_music: parsedData?.model?.energy_music ?? prevState?.settings?.music?.energy_music,
            hardcord_music: parsedData?.model?.hardcord_music ?? prevState?.settings?.music?.hardcord_music
          };
  
          const jingleSettings = {
            enable_jingle: parsedData?.model?.enable_jingle ?? prevState?.settings?.jingle?.enable_jingle,
            enable_time: parsedData?.model?.enable_time ?? prevState?.settings?.jingle?.enable_time,
            user_time_jingle: parsedData?.model?.user_time_jingle ?? prevState?.settings?.jingle?.user_time_jingle
          };
  
          const othersSettings = {
            frequency: parsedData?.model?.news_insert_frequency ?? prevState?.settings?.others?.frequency,
            voices: {
              rss_voice: parsedData?.model?.rss_voice ?? prevState?.settings?.others?.voices?.rss_voice,
              gpt_voice: parsedData?.model?.gpt_voice ?? prevState?.settings?.others?.voices?.gpt_voice,
              jingle_voice: parsedData?.model?.jingle_voice ?? prevState?.settings?.others?.voices?.jingle_voice
            }
          };
  
          return {
            ...prevState,
            settings: {
              general: generalSettings,
              music: musicSettings,
              jingle: jingleSettings,
              others: othersSettings
            }
          };
        });
      }});
    });
    } catch(error){
  
    }
  }

  useEffect(() => {
    function isEmpty(obj) {
      return JSON.stringify(obj).length -2 ? true : false;
    }
    if(isEmpty(sessionFormData)){
      console.log(sessionFormData.settings);
      setDataFromDB();
    }
  }, []);

  const sendSettingsToServer = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const selectedMusic = {
        slow_music: !!sessionFormData.settings?.music?.slow_music,
        energy_music: !!sessionFormData.settings?.music?.energy_music,
        hardcord_music: !!sessionFormData.settings?.music?.hardcord_music,
      };
      
      const jingleData = {
        jingle: !!sessionFormData.settings?.jingle?.enable_jingle,
        time: !!sessionFormData.settings?.jingle?.enable_time,
      };
  
      const selectedVoices = {
        rss_voice: sessionFormData.settings?.others?.voices?.rss_voice,
        jingle_voice: sessionFormData.settings?.others?.voices?.jingle_voice,
        gpt_voice: sessionFormData.settings?.others?.voices?.gpt_voice,
      };

      const freqData = sessionFormData.settings?.others?.frequency;
      const additionalInputValue = sessionFormData.settings?.jingle?.user_time_jingle;
      const selectedTimezoneName = sessionFormData.settings?.general?.timezone_name;
      const selectedTimezoneOffset = sessionFormData.settings?.general?.timezone;

      const requestData = {
        email: userId,
        type: JSON.stringify(selectedMusic),
        jingle: JSON.stringify(jingleData),
        user_time_jingle: additionalInputValue,
        freq: freqData,
        timezone: selectedTimezoneOffset,
        timezone_name: sessionFormData.settings?.general?.timezone_name,
        voices: JSON.stringify(selectedVoices),
        station_name: sessionFormData.settings?.general?.station_name,
        station_description: sessionFormData.settings?.general?.station_description,
      };

      let formData = new FormData();
      if (sessionFormData?.file) {
        formData.append('file', sessionFormData?.file);
      }

      Object.keys(requestData).forEach(key => {
        formData.append(key, requestData[key]);
      });

      if (selectedTimezoneName) {
        formData.append('timezone_name', selectedTimezoneName);
      }

      let response = await fetch(process.env.REACT_APP_API_URL + '/settings/update', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        body: formData,
      });
      
      if (!response.ok) {
        throw new Error('Failed to save');
      }

      response = await fetch(process.env.REACT_APP_API_ICE_URL + '/settings/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData), // Полные данные для второй ручки
      });
      
      if (!response.ok) {
        throw new Error('Failed to send data to the server');
      }

      message.success('Данные успешно сохранены');
    } catch (error) {
      console.error('Error sending data to the server:', error.message);
    }
  };


  const onFinish = async () => {
    await setLoading(true);
    await sendSettingsToServer();
    setTimeout(() => {
      console.log(FormData);
    }, 1000);
    setLoading(false);
  };

  return (
    <div className="settings" style={{ height: '100vh' }}>
    <Form className="neuro-settings-form"  onFinish={onFinish}>
      <Flex  justify='center' align="center" style={{ width: '100%' }}>
        {/* <Tabs defaultActiveKey="general" style={{ width: '30%', marginBottom: '20px' }}>
          <TabPane tab="Основные" key="general" />
          <TabPane tab="Музыка" key="music" />
          <TabPane tab="Отбивки" key="jingle" />
          <TabPane tab="Прочее" key="others" />
        </Tabs> */}
        <div style={{ width: '66%', marginBottom: '0.5vh' }}>
          <Tabs defaultActiveKey="general" style={{ width: '100%', height: '100%' }}>
            <TabPane tab="Основные" key="general">
              <SettingsGeneral />
            </TabPane>
            <TabPane tab="Музыка" key="music">
              <SettingsMusic />
            </TabPane>
            <TabPane tab="Отбивки" key="jingle">
              <SettingsJingle />
            </TabPane>
            <TabPane tab="Прочее" key="others">
              <SettingsOthers />
            </TabPane>
          </Tabs>
        </div>
        
      </Flex>
      <Flex justify='center' align="center">
      <Form.Item style={{ textAlign: 'left', width: '66%' }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Сохранить
        </Button>
      </Form.Item>
      </Flex>
    </Form>
  </div>
  );
};

export default SettingsForm;