import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Select, Row, Col, message, List, Flex } from 'antd';
import { FormDataContext } from '../globalContext';

const { Option } = Select;

const RssNews = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const {sessionFormData, setSessionFormData} = useContext(FormDataContext);
  const [newsData, setNewsData] = useState([]);

  const fetchNews = async () => {
    if(sessionFormData.subscriptionMessage === "") {
      try {
        const response = await fetch(process.env.REACT_APP_API_ICE_URL + '/rss/get/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({email: localStorage.getItem('userId')})
        });
        const data = await response.json();
        setNewsData(Object.entries(data)); // Преобразование объекта в массив пар [ключ, значение]
      } catch (error) {
        //console.error(error);
        //message.error('Ошибка при загрузке новостей.');
      }
    }
  };

  useEffect(() => {
    fetchNews();
    const intervalId = setInterval(fetchNews, 60 * 60 * 10000); // Проверять новости каждые 60 секунд
    if (sessionFormData.rss) {
      form.setFieldsValue(sessionFormData.rss);
    }

    return () => clearInterval(intervalId); // Очистка интервала при размонтировании компонента
  }, []);

  const getDayForm = (value) => {
    if (value % 10 === 1 && value % 100 !== 11) {
      return `${value} день`;
    } else if ([2, 3, 4].includes(value % 10) && ![12, 13, 14].includes(value % 100)) {
      return `${value} дня`;
    } else {
      return `${value} дней`;
    }
  }


  const onFinish = async (values) => {
    setLoading(true);

    const { rss_url, max_deley, number_of_news, delete_every } = values;
    const userId = localStorage.getItem("userId"); // Получите userId из контекста или хранилища
    const data = {
      email: userId,
      rss_url: rss_url,
      max_count: max_deley,
      number_of_news: number_of_news,
      update_every: delete_every,
    };
    try {
      fetch(process.env.REACT_APP_API_URL + '/rss/update',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(data)
      }
      ).then(response => {
        const responseJS = response.json();
        console.log(responseJS);
      });

      fetch(process.env.REACT_APP_API_ICE_URL + '/parse_rss', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then(() => {
        message.success('Данные успешно сохранены.');
      });

    } catch (error) {
      console.error(error);
      message.error('Ошибка при сохранении данных.');
    } finally {
      setSessionFormData({ ...sessionFormData, rss: values });
      message.success('Данные успешно отправленны');
      setLoading(false);
    }
  };

  return (
    <div className='rss-container' style={{ height: '100vh' }}>
      {sessionFormData.subscriptionMessage ? <div className="subscription-message" style={{ height: '100vh' }}>{sessionFormData.subscriptionMessage}</div> : (
        <>
      <Form form={form} name="rss_form" onFinish={onFinish}>
        <label>URL RSS:</label><br></br>
        <Form.Item name="rss_url" rules={[{ required: true, message: 'Введите URL RSS' }]} style={{width: '33.33vw'}}>
          <Input />
        </Form.Item>
        <Flex justify='flex-start' align='flex-start'>
          <Form.Item label='Время обновления' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="max_deley" rules={[{ required: true, message: 'Выберите значение' }]}>
            <Select style={{ width: '8em' }}>
                {[...Array(31).keys()].map((value) => (
                  <Option key={value + 1} value={value + 1}>
                    {getDayForm(value + 1)}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label='Количество новостей' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="number_of_news" rules={[{ required: true, message: 'Выберите значение' }]}>
            <Select style={{ width: '8em' }}>
                {[...Array(100).keys()].map((value) => (
                  <Option key={value + 1} value={value + 1}>
                    {value + 1}
                  </Option>
                ))}
              </Select>
          </Form.Item>
          {/* <Form.Item label='Удалять новости через' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="max_deley" rules={[{ required: true, message: 'Выберите значение' }]}>
          <Select style={{ width: '8em' }}>
              {[...Array(31).keys()].map((value) => (
                <Option key={value + 1} value={value + 1}>
                  {getDayForm(value + 1)}
                </Option>
              ))}
            </Select>
          </Form.Item> */}
          </Flex>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
      <List
        header={<div>План новостей</div>}
        bordered
        dataSource={newsData}
        renderItem={item => (
          <List.Item>
            <strong>{item[0]}:</strong> {item[1]}
          </List.Item>
        )}
      /></>)}
    </div>
  );
};

export default RssNews;
