// src/App.js
import React, { useState, useEffect, useContext } from 'react';
import {useNavigate} from 'react-router-dom';
import { Form, Button, Typography } from 'antd';
import { useSessionFormData } from '../globalContext';
import AudioPlayer from './audioPlayer';
const { Paragraph } = Typography;

const Neuro = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isStreaming, setIsStreaming] = useState(localStorage.getItem('isStreaming'));
  const {sessionFormData, setSessionFormData} = useSessionFormData();
  const [isClicked, setIsClicked] = useState(false);
  const [streamUrl, setStreamUrl] = useState('');
  
  const checkMountpoint = () => {
    fetch(process.env.REACT_APP_API_ICE_URL + '/stream/status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: localStorage.getItem('userId') })
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
          const streamingStatus = data.status === true;
          setIsStreaming(streamingStatus);
          setSessionFormData(prevFormData => ({
            ...prevFormData,
            isStreaming: streamingStatus
          }));
        });
      } else if(response.status === 404) {
        console.log('404');
        setIsStreaming(false);
        setIsClicked(false);
      }
    })
    .catch(error => {
      console.error('Error checking mountpoint:', error);
      setIsStreaming(false);
    });
  };
  const handleButtonClick = async () => {
    fetch(process.env.REACT_APP_API_ICE_URL + '/stream/start', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: localStorage.getItem('userId')})
    }).then(response => {
      if(response.ok){
        console.log('Button clicked');
        setIsStreaming(true);
        setSessionFormData(prevFormData => ({
          ...prevFormData,
          isStreaming: isStreaming
    }));
      }
    }
    )
  };

  const handlePlayerButton = () => {
    navigate('/neuro/player');
    window.location.reload();
  }

  useEffect(() => {
    checkMountpoint();
    console.log(process.env.REACT_APP_ICECAST_URL + '/' + localStorage.getItem('stationName') + '/stream');
    const url = process.env.REACT_APP_ICECAST_URL + '/' + localStorage.getItem('stationName') + '/stream.mp3';
    setStreamUrl(url);
  }, []);
  const audioCodeString = `<audio controls src="${streamUrl}"></audio>`;

  return (
    <div className="site-login" style={{ height: '200vh' }}>
      <h1>Ваша станция</h1>
      {(!isStreaming) ? (
        <Button id="signup-button" type="primary" onClick={handleButtonClick}>
          Запустить поток
        </Button>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'top', padding: '0.5%', marginBottom: '15vh' }}>
            <AudioPlayer />
          </div>
          
          <Paragraph style={{ marginTop: '15vh' }}>
            <Typography.Text strong>Вы можете скопировать этот код в свой сайт:</Typography.Text> <br/>
            <Typography.Text code>{audioCodeString}</Typography.Text>
          </Paragraph>
        </>
      )}
     
    </div>
  );
  //<Input value={userId} disabled addonAfter={<a href="#">Прямая ссылка</a>} />
};

export default Neuro;
