import React, { useState, useEffect, useRef } from 'react';
import { Card, Avatar } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';


const { Meta } = Card;

const AudioPlayer = () => {
  const [stationData, setStationData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [userPaused, setUserPaused] = useState(false);
  const audioRef = useRef(new Audio());

  useEffect(() => {
    const fetchStationData = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_API_URL + '/settings/get-station-data', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({ email: localStorage.getItem('userId') })
          });
          if (response.ok) {
            const json = await response.json();
            const data = typeof json === 'string' ? JSON.parse(json) : json; 
            console.log('Преобразованные данные:', data);
            setStationData(data);
          } else {
            throw new Error('Network response was not ok.');
          }
        } catch (error) {
          console.error('Ошибка при получении данных станции:', error);
        }
      };
    
      fetchStationData();
  }, []);

  useEffect(() => {
    const audio = audioRef.current;

    const handleAudioError = () => {
      console.error('Ошибка в аудиопотоке, пытаемся восстановить...');
      initializeAndPlay();
    };

    const handleAudioEnd = () => {
      console.log('Аудиопоток окончен, пытаемся возобновить...');
      initializeAndPlay();
    };

    const handlePause = () => {
      if (!userPaused) {
        console.log('Пауза не инициирована пользователем, пытаемся восстановить...');
        initializeAndPlay();
      }
    };
    const handleStopped = () => {
        initializeAndPlay();
    }

    audio.addEventListener('error', handleAudioError);
    audio.addEventListener('ended', handleAudioEnd);
    audio.addEventListener('pause', handlePause);
    audio.addEventListener('stopped', handleStopped);

    return () => {
      audio.removeEventListener('error', handleAudioError);
      audio.removeEventListener('ended', handleAudioEnd);
      audio.removeEventListener('pause', handlePause);
    };
  }, [userPaused]);


  const initializeAndPlay = async () => {
    audioRef.current.src = process.env.REACT_APP_ICECAST_URL+'/'+localStorage.getItem('stationName')+'/stream.mp3';
    audioRef.current.load();
    try {
      await audioRef.current.play();
      setIsPlaying(true);
      setUserPaused(false);
    } catch (error) {
      console.error('Ошибка при попытке автовоспроизведения:', error);
    }
  };

  const togglePlay = async () => {
    setUserPaused(isPlaying);
    if (!isPlaying) {
      await initializeAndPlay();
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div style={{ margin: '20px', marginBottom: '15vh' }}>
      {stationData && (
        <>
            <Card
            style={{ width: 'auto', maxWidth: '10vw', height: 'auto', maxHeight: '10vh' }}
            cover={<img alt="logo" src={stationData.logo} />}
            actions={[
                isPlaying ? <PauseCircleOutlined key="pause" onClick={togglePlay} /> : <PlayCircleOutlined key="play" onClick={togglePlay} />
            ]}
            >
            <Meta
                avatar={<Avatar src={stationData.logo} />}
                title={stationData.name}
                description={stationData.station_description}
            />
            </Card>
            <audio ref={audioRef} />
        </>
        )}
    </div>
  );
};

export default AudioPlayer;
