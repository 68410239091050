import React, { useState, useEffect } from 'react';
import { Input, Button, Table } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ScheduleAndStatistic = () => {
  const [schedule, setSchedule] = useState([]);
  const [subscription, setSubscription] = useState();
  const [statistics, setStatistics] = useState({
    listeners: 0,
    onlineTime: 'N/A',
    nowPlaying: ''
  });

  const fetchStatistics = async () => {
    const userId = localStorage.getItem('userId');
    try {
      const response_icecast = await fetch(process.env.REACT_APP_ICECAST_URL + '/status-json.xsl');
      const response_backend = await fetch(process.env.REACT_APP_API_ICE_URL + '/stat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: userId})
      });
      const jsonData = await response_icecast.json();
      const jsonBackendData = await response_backend.json();

      const listenUrl = localStorage.getItem('stationName') + '/stream.mp3'; 
      const source = jsonData.icestats.source.find(source => source.listenurl.endsWith(listenUrl));
      if (source) {
        const onlineTime = calculateOnlineTime(source.stream_start);
        setStatistics({
          listeners: source.listeners,
          onlineTime: onlineTime,
          nowPlaying: jsonBackendData.now_playing
        });
      } else {
        console.error('Источник не найден');
      }
    } catch (error) {
      console.error('Ошибка получения статистики:', error);
    }
  };

  const calculateOnlineTime = (streamStart) => {
    if (!streamStart) return 'N/A';

    const startTime = new Date(streamStart);
    const now = new Date();
    const diff = now - startTime;

    let hours = Math.floor(diff / 3600000);
    let minutes = Math.floor((diff % 3600000) / 60000);

    return `${hours} ч ${minutes} мин`;
  };

  useEffect(() => {
    const fetchAndUpdate = () => {
      fetchStatistics();
      checkSubscription();
      refreshSchedule();
    };
    fetchAndUpdate();
    const intervalId = setInterval(() => {
      fetchAndUpdate();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const updateSchedule = (data) => {
    setSchedule(data);
  };

  const deleteRowOnRemoteServer = async (filename, position) => {
    const userId = localStorage.getItem('userId');
    const requestData = {
      email: userId,
      action: 'delete',
      filename: filename,
      pos: position
    };

    try {
      const response = await fetch(process.env.REACT_APP_API_ICE_URL + '/schedule/edit/', {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const result = await response.json();
      console.log('Запись удалена на удаленном сервере:', result);
      updateSchedule(result);
    } catch (error) {
      console.error('Ошибка удаления записи на удаленном сервере:', error);
    }
  };

  const refreshSchedule = async () => {
    const userId = localStorage.getItem('userId');
    const jsEmail = { email: userId };
    try {
      const response = await fetch(process.env.REACT_APP_API_ICE_URL + '/schedule', {
        method: 'POST',
        body: JSON.stringify(jsEmail),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      updateSchedule(data);
    } catch (error) {
      console.error(error);
    }
  };

  const checkSubscription = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/user/get-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify({ id: localStorage.getItem('userId') }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const subscription_ = await response.json();
      console.log(subscription_);
      const subscriptionEnd = new Date(subscription_.ends_at);
      const now = new Date();

      let newMessage = '';
      if (subscription_.name === 'trial') {
        newMessage = 'У вас нет подписки на сервис, доступен лишь базовый функционал! Пожалуйста приобретите подписку.';
        setSubscription(newMessage);
      } else if (subscriptionEnd < now) {
        newMessage = 'Ваша подписка истекла, пожалуйста оформите её ещё раз!';
        setSubscription(newMessage);
      } else {
        newMessage = subscription_.name;
        setSubscription(newMessage);
      }

    } catch (error) {
      console.error('There was an error checking the subscription:', error);
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedSchedule = Array.from(schedule);
    const [movedItem] = reorderedSchedule.splice(result.source.index, 1);
    reorderedSchedule.splice(result.destination.index, 0, movedItem);

    setSchedule(reorderedSchedule);

    // Send the updated order to the server
    try {
      const userId = localStorage.getItem('userId');
      const response = await fetch(process.env.REACT_APP_API_ICE_URL + '/schedule/edit/', {
        method: 'POST',
        body: JSON.stringify({
          email: userId,
          action: 'change_position',
          pos: result.source.index,
          newPos: result.destination.index
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const resultData = await response.json();
      console.log('Schedule updated on the server:', resultData);
    } catch (error) {
      console.error('Ошибка обновления порядка на сервере:', error);
    }
  };

  return (
    <div className="employee-create" style={{ height: '100%' }}>
      <div className="row">
        <div className="col-lg-5">
          <p>Число слушателей: {statistics.listeners}</p>
          <p>Время онлайн: {statistics.onlineTime}</p>
          <p>Сейчас играет: {statistics.nowPlaying}</p>
          <p>Активная подписка: {subscription} 
            <Button type="primary" htmlType="submit"> Перейти на дорого-богато </Button>
          </p>
        </div>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="schedule">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {schedule.map((item, index) => (
                <Draggable key={item.filename} draggableId={item.filename} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div style={{ marginBottom: '8px', padding: '16px', border: '1px solid #ddd' }}>
                        <p>{item.filename}</p>
                        <p>{item.type}</p>
                        <Button
                          type="danger"
                          onClick={() => deleteRowOnRemoteServer(item.filename, index)}
                        >
                          Удалить
                        </Button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ScheduleAndStatistic;
