import React, {useState, useEffect, createContext, useContext} from "react";

const FormDataContext = createContext(null);

const FormDataProvider = ({children}) => {
    const [sessionFormData, setSessionFormData] = useState(() => {
        const savedData = sessionStorage.getItem("sessionFormData");
        const token = localStorage.getItem("token");
        const isAuthenticated = token !== "undefined" && token !== null;
        const isStreaming = localStorage.getItem('isStreaming');
        return savedData ? JSON.parse(savedData) : { settings: {
            general: {},
            music: {},
            jingle: {},
            others: {}
        }, 
        rss: {},
        ai: {}, 
        isStreaming, 
        file: null,
        isAuthenticated, 
        subscriptionMessage: ''};
    });

    useEffect(() => {
        sessionStorage.setItem("sessionFormData", JSON.stringify(sessionFormData));
    }, [sessionFormData]);

    const login = () => {
        const token = localStorage.getItem("token");
        setSessionFormData(prevFormData => ({
            ...prevFormData,
            isAuthenticated: token !== "undefined" && token !== null
        }));
    };

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("stationName");
        localStorage.removeItem("userId");
        localStorage.removeItem('isStreaming');
        localStorage.removeItem('role');
        setSessionFormData(prevFormData => ({
            ...prevFormData,
            isAuthenticated: false,
            settings: {},
            rss: {},
            isStreaming: false,
            file: null,
            subscriptionMessage: "",
        }));
    };
    

    return (<FormDataContext.Provider value={{sessionFormData, setSessionFormData, login, logout}}>
        {children}
    </FormDataContext.Provider>);
}

const useSessionFormData = () => {
    const context = useContext(FormDataContext);
    if(!context){
        throw new Error('useFormData must be used within a FormDataProvider');
    }
    return context;
}

export default FormDataProvider;
export { useSessionFormData, FormDataContext};