import React, {useEffect, useState} from 'react';
import { Table, Button } from 'antd';
import axios from 'axios';

const AdminTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL+'/user/get-users',{
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }); 
        if (response.data && response.data.length > 0) {
            setData(response.data[0]);
        }
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      title: 'Имя пользователя',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Почта',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Название станции',
      dataIndex: 'station_name',
      key: 'station_name',
    },
    {
      title: 'Юр. лицо',
      dataIndex: 'is_company',
      key: 'is_company',
      render: is_company => (is_company === "1" ? "Да" : "Нет"),
    },
    {
      title: 'Подписка',
      dataIndex: 'subscription_string',
      key: 'subscription_string',
    },
    {
      title: 'Действие',
      key: 'action',
      render: (text, record) => (
        <>
        <Button danger onClick={() => handleDelete(record.email)}>
          Удалить
        </Button>
        <Button submit onClick={() => handleRestart(record.email)}>
          Перезапустить
        </Button>
        </>
      ),
    },
  ];

  const handleRestart = key => {
    console.log(key);
    fetch(process.env.REACT_APP_API_ICE_URL + '/stream/restart', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: key})
    }).then(response => {

    });
  };

  const handleDelete = key => {
    // Логика удаления пользователя
    fetch(process.env.REACT_APP_API_ICE_URL + '/station/remove', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: key})
    }).then(response => {

    });
    console.log('Удален пользователь с ключом:', key);
  };

  return <Table columns={columns} dataSource={data} />;
};

export default AdminTable;
