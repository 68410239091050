import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useSessionFormData } from '../globalContext';

const EmployeeCreate = () => {
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const { sessionFormData, setSessionFormData } = useSessionFormData();

  const handleFileChange = (event) => {
    // Установка файла, если он выбран
    if (event && event.target && event.target.files[0]) {
      setFile(event.target.files[0]);
    } else {
      setFile(null);
    }
  };

  const onFinish = async () => {
    if (!file) {
      message.error('Пожалуйста, выберите файл для загрузки.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file, file.name);

    const userId = localStorage.getItem('userId'); // Замените на актуальное значение
    const emailData = JSON.stringify({ "email": userId });
    formData.append('email', emailData);

    try {
      const response = await fetch(process.env.REACT_APP_API_ICE_URL + '/upload/file/', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        //const responseData = await response.json();
        message.success('Файл успешно загружен.');
        //console.log('File uploaded successfully:', responseData);
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      message.error('Ошибка при загрузке файла.');
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div>
      {sessionFormData.subscriptionMessage ? (<div className="subscription-message" style={{ height: '100vh' }}>{sessionFormData.subscriptionMessage}</div>) : (
    <div className="employee-create" style={{ marginTop: '20px', height: '100vh' }}>
        <Row justify="space-between" align="middle">
          <Col>
            <h2>Свои треки</h2>
          </Col>
          <Col>
            <Form form={form} onFinish={onFinish} layout="inline">
              <Form.Item>
                <Input type="file" onChange={handleFileChange} accept=".mp3" style={{ width: '300px' }} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" icon={<UploadOutlined />}>
                  Добавить трек
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      
    </div>
    )}
    </div>
  );
};

export default EmployeeCreate;
