import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select } from 'antd';

const CompanyDropdown = (props) => {
  const [companies, setCompanies] = useState([]);
  const { value, onChange } = props;

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/site/companies-list'); // Adjust the endpoint based on your backend API
        setCompanies(response.data.companies); // Устанавливаем компании из ответа
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, []); // Empty dependency array means this effect runs once on component mount

  return (
    <Select value={value} onChange={onChange} placeholder='Выберите организацию'>
      {companies.map(company => (
        <Select.Option key={company.name} value={company.name}>
          {company.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CompanyDropdown;
