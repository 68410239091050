import React, { useState } from 'react';
import { Layout, Button, Badge, Modal, Tooltip } from 'antd';
import { LogoutOutlined, LoginOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useSessionFormData } from './globalContext';
import axios from 'axios';

const { Header } = Layout;


const HeaderComponent = () => {
    const { sessionFormData, logout, login } = useSessionFormData();
    const { isAuthenticated, isStreaming } = sessionFormData;
    const [supportModalVisible, setSupportModalVisible] = useState(false); // Состояние видимости модального окна

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("stationName");
        localStorage.removeItem("userId");
        localStorage.removeItem('isStreaming');
        //setIsAuthenticated(false);
        //logout;
        //setIsStreaming(false);
    };
    const [collapsed, setCollapsed] = useState(false);
      
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const showSupportModal = () => {
      setSupportModalVisible(true); // При нажатии кнопки отображается модальное окно
    };

    const hideSupportModal = () => {
        setSupportModalVisible(false); // При нажатии на кнопку "Закрыть" модальное окно скрывается
    };

    return (
      <Header style={{ backgroundColor: '#001529', padding: '20', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', marginRight: '8px' }}>
                  Нейро-Радио
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  {sessionFormData.isStreaming === true && localStorage.getItem('role') === 'Admin' ? (
                      <div style={{ marginLeft: 10, marginRight: 10 }}>
                          <Badge color="red" />
                          <span style={{ marginLeft: 8, fontWeight: 'bold', color: 'red' }}>В ЭФИРЕ</span>
                      </div>
                  ) : (<></>)}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  {isAuthenticated && localStorage.getItem('role') === 'Admin' ? (
                      <Button type="primary" size="large" style={{ padding: '30' }} href="/site/subscription">
                          Оплата и тарифы
                      </Button>
                  ) : <></>}
              </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
            {/* Кнопка с иконкой вопроса и аннотацией */}
            <Tooltip title="Техническая поддержка">
                <Button type="default" icon={<QuestionCircleOutlined />} size="large" onClick={showSupportModal} />
            </Tooltip>
            {/* Модальное окно с информацией о почте поддержки */}
            <Modal
                title="Поддержка"
                visible={supportModalVisible}
                onCancel={hideSupportModal}
                footer={[
                    <Button key="close" onClick={hideSupportModal}>Закрыть</Button>
                ]}
            >
                <p>Если у вас возникли вопросы, пожалуйста, свяжитесь с нами по адресу support@neuroradio.ru</p>
            </Modal>
            {isAuthenticated ? (
                <Button type="primary" icon={<LogoutOutlined />} size="large" onClick={logout} href='/site/login'>
                    Выход
                </Button>
            ) : (
                <Button type="primary" icon={<LoginOutlined />} size="large" onClick={login} href='/site/login'>
                    Вход
                </Button>
            )}
        </div>
      </Header>
  );
}

export default HeaderComponent;