import React, { useEffect, useState } from "react";
import { useSessionFormData } from "./globalContext";

const SubscriptionChecker = ({isMainPage}) => {
    const userId = localStorage.getItem('userId')
    const { sessionFormData, setSessionFormData } = useSessionFormData();

    const checkSubscription = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + '/user/get-subscription', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          },
          body: JSON.stringify({ id: userId }),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const subscription = await response.json();
        console.log(subscription);
        const subscriptionEnd = new Date(subscription.ends_at);
        const now = new Date();
  
        let newMessage = '';
        if (subscription.name === 'trial') {
            newMessage = 'У вас нет подписки на сервис, доступен лишь базовый функционал! Пожалуйста приобретите подписку.';
        } else if (subscriptionEnd < now) {
            newMessage = 'Ваша подписка истекла, пожалуйста оформите её ещё раз!';
        }
        setSessionFormData(prevFormData => ({
            ...prevFormData,
            subscriptionMessage: newMessage
          }));
  
      } catch (error) {
        console.error('There was an error checking the subscription:', error);
      }
    };
  
    useEffect(() => {
      const checkAtMidnight = () => {
        const now = new Date();
        const nextMidnight = new Date(now);
        nextMidnight.setHours(1, 0, 0, 0);
        return nextMidnight.getTime() - now.getTime();
      };
  
      const timeoutId = setTimeout(() => {
        checkSubscription();
        setInterval(checkSubscription,  60 * 1000); // проверять каждые 24 часа
      }, checkAtMidnight());
  
      return () => clearTimeout(timeoutId);
    }, [localStorage.getItem('token') !== "undefined"]);
  
    if (isMainPage) {
        return null;
    } else {
        return (
            <div style={{ height: '200vh' }}>
                {sessionFormData.subscriptionMessage && <p>{sessionFormData.subscriptionMessage}</p>}
            </div>
        );
    }
  };
  
  export default SubscriptionChecker;