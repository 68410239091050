import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Checkbox, Select, Button, Modal, Avatar } from 'antd';
import axios from 'axios';
import CompanyDropdown from './companyDropdown';

const { Option } = Select;

const Signup = () => {
    const [isCompany, setIsCompany] = useState(false);
    const [signupValues, setSignupValues] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [form] = Form.useForm();
    const [isExistingCompany, setIsExistingCompany] = useState(false);
    const [isAdminOptionAvailable, setIsAdminOptionAvailable] = useState(false);
    const [isCodeModalVisible, setIsCodeModalVisible] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');

    const navigate = useNavigate();

    const handleCodeSubmit = async () => {
        try {
            const code = confirmationCode;
            const response = await axios.post(process.env.REACT_APP_API_URL + '/site/signup-confirm', {
                email: signupValues.email,
                code: code,
            });
            if (response.data.success) {
                navigate('/site/login');
            } else {
                // Handle error
            }
        } catch (error) {
            console.error('Error during code confirmation:', error);
        }
    };

    const onFinish = async (values) => {
        try {
            values.role = values.role === 'superadmin' ? "SuperAdmin" : "Admin";
            const response = await axios.post(process.env.REACT_APP_API_URL + '/site/signup', values);
            console.log(response);
            if (response.data && response.data.error) {
                const errorsFromServer = JSON.parse(response.data.error);
                let newFormErrors = {};
                if (errorsFromServer.username) {
                    newFormErrors.username = errorsFromServer.username[0];
                }
                if (errorsFromServer.email) {
                    newFormErrors.email = errorsFromServer.email[0];
                }
                if (errorsFromServer.station_name) {
                    newFormErrors.station_name = errorsFromServer.station_name[0];
                }
                
                setFormErrors(newFormErrors);
                return;
            }
            setSignupValues(values);
            setIsCodeModalVisible(true);
        } catch (error) {
            console.error('Error during signup:', error);
        }
    };

    useEffect(() => {
        const checkAdminStatus = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/site/admin');
                setIsAdminOptionAvailable(response.role);
            } catch (error) {
                console.error('Error checking admin status:', error);
            }
        };

        checkAdminStatus();
    }, []);

    const toggleCompanyFields = () => {
        setIsCompany(!isCompany);
        setIsExistingCompany(false);
    };

    const toggleExistingCompany = () => {
        setIsExistingCompany(!isExistingCompany);
    };

    const validatePasswordsMatch = (_, value) => {
        if (value && value !== form.getFieldValue('password')) {
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        }
        return Promise.resolve();
    };

    const handleCodeChange = (e) => {
        const newValue = e.target.value;
        const sanitizedValue = newValue.replace(/\D/g, '').slice(0, 4);
        setConfirmationCode(sanitizedValue);
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text');
        const sanitizedValue = pasteData.replace(/\D/g, '').slice(0, 4);
        setConfirmationCode(sanitizedValue);
    };

    const logoPath = '/224.png';

    return (
        <div className="site-signup" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Form
                name="signup-form"
                form={form}
                onFinish={onFinish}
                initialValues={{ is_company: false, existing_company: false }}
                style={{ width: 'auto', minHeight: '100%' }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Avatar src={logoPath} size={256} style={{ marginBottom: '24px', backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center' }} />
                </div>
                <Form.Item name="username" rules={[{ required: true, message: 'Пожалуйста, введите имя пользователя!' }]} validateStatus={formErrors.username && "error"} help={formErrors.username}>
                    <Input autoFocus placeholder="Username" />
                </Form.Item>

                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: 'Пожалуйста, введите почту!' },
                        { type: 'email', message: 'Введите корректный email!' }
                    ]}
                    validateStatus={formErrors.email && "error"}
                    help={formErrors.email}
                >
                    <Input placeholder="Email" />
                </Form.Item>

                <Form.Item name="is_company" valuePropName="checked">
                    <Checkbox onChange={toggleCompanyFields}>Регистрация от Юр.лица?</Checkbox>
                </Form.Item>

                {isCompany && isExistingCompany && (
                    <Form.Item name="company_name">
                        <CompanyDropdown />
                    </Form.Item>
                )}

                {isCompany && !isExistingCompany && (
                    <>
                        <Form.Item name="company_name">
                            <Input placeholder="Название организации" />
                        </Form.Item>
                    </>
                )}
                {isAdminOptionAvailable && (
                    <Form.Item name="role">
                        <Select placeholder="Select Role">
                            <Option value="superadmin">Super Admin</Option>
                            <Option value="admin">Admin</Option>
                        </Select>
                    </Form.Item>
                )}
                {!isExistingCompany && (
                    <Form.Item name="station_name" rules={[{ required: true }]} help={formErrors.station_name}>
                        <Input placeholder="Название станции" />
                    </Form.Item>
                )}

                <Form.Item name="password" id="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                    <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item name="password_repeat" dependencies={['password']} rules={[{ required: true, message: 'Please repeat your password!', validator: validatePasswordsMatch }]}>
                    <Input.Password placeholder="Repeat Password" />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10%' }}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Зарегистрироваться
                        </Button>
                    </Form.Item>
                </div>
            </Form>
            <Modal
                title="Введите код подтверждения"
                visible={isCodeModalVisible}
                onOk={handleCodeSubmit}
                onCancel={() => setIsCodeModalVisible(false)}
            >
                <Input
                    value={confirmationCode}
                    onChange={handleCodeChange}
                    onPaste={handlePaste}
                    placeholder="Введите код подтверждения"
                    maxLength={4}
                    style={{ width: '100%', textAlign: 'center' }}
                />
            </Modal>
        </div>
    );
};

export default Signup;
