import React, { useState, useEffect } from 'react';
import { Form, Checkbox, Input } from 'antd';
import { useSessionFormData } from '../globalContext';

const SettingsJingle = () => {
  const [form] = Form.useForm();
  const { sessionFormData, setSessionFormData } = useSessionFormData();
  const [isJingleEnabled, setIsJingleEnabled] = useState(false);

  const handleFormChange = (changedValues, allValues, allFields) => {
    {
      // Проверяем состояние чекбоксов
      const jingleEnabled = allValues.jingle?.enable_jingle || allValues.jingle?.enable_time;
      setIsJingleEnabled(jingleEnabled);
    };
    setSessionFormData(prevData => {
      const updatedJingleSettings = {
        enable_jingle: allValues.jingle?.enable_jingle ?? prevData.settings.jingle?.enable_jingle,
        enable_time: allValues.jingle?.enable_time ?? prevData.settings.jingle?.enable_time,
        user_time_jingle: allValues.jingle?.user_time_jingle ?? prevData.settings.jingle?.user_time_jingle,
      };
      return {
        ...prevData,
        settings: {
          ...prevData.settings,
          jingle: updatedJingleSettings
        }
      };
    });
  };

  useEffect(() => {
    // Начальное состояние поля на основе данных сессии
    setIsJingleEnabled(
      sessionFormData.settings?.jingle?.enable_jingle ||
      sessionFormData.settings?.jingle?.enable_time
    );
  }, [sessionFormData]);

  // const handleFormChange = (_, allFields) => {
  //   // Проверяем состояние чекбоксов
  //   const jingleEnabled = allFields.some(field => 
  //     (field.name[1] === 'enable_jingle' || field.name[1] === 'enable_time') && field.value
  //   );
  //   setIsJingleEnabled(jingleEnabled);
  // };

  return (
    <div className='jingle-container'>
      {sessionFormData.subscriptionMessage ? (
        <div className="subscription-message">{sessionFormData.subscriptionMessage}</div>
      ) : (
        <Form form={form} onValuesChange={handleFormChange}>
          <div className="jingle-container">
            <h2>Отбивки</h2>
            <Form.Item
              initialValue={sessionFormData.settings?.jingle?.enable_jingle ? sessionFormData.settings?.jingle?.enable_jingle : false}
              name={['jingle', 'enable_jingle']}
              valuePropName="checked"
            >
              <Checkbox id="jingle">Включить отбивки</Checkbox>
            </Form.Item>
            <Form.Item
              initialValue={sessionFormData.settings?.jingle?.enable_time ? sessionFormData.settings?.jingle?.enable_time : false}
              name={['jingle', 'enable_time']}
              valuePropName="checked"
            >
              <Checkbox id="enable_time">Включить отбивки каждый час</Checkbox>
            </Form.Item>
            {isJingleEnabled && (<>
              <label>Текст отбивок</label><br/>
              <Form.Item
                initialValue={sessionFormData.settings?.jingle?.user_time_jingle 
                  && sessionFormData.settings?.jingle?.user_time_jingle != (0 || null || undefined) ? sessionFormData.settings?.jingle?.user_time_jingle : "Введите текст отбивки"}
                name={['jingle', 'user_time_jingle']}
                style={
                  { 
                    width: '50%', 
                  }
                  }
              >
                <Input.TextArea id="user_time_jingle" 
                // style={
                //   { 
                //     width: '50%', 
                //   }
                //   }
                  />
              </Form.Item>
            </>)}
          </div>
        </Form>
      )}
    </div>
  );
};

export default SettingsJingle;