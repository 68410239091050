import React, { useState, useEffect } from 'react';
import { Button, Modal, Input, Form, message, Slider, Spin } from 'antd';
import { useSessionFormData } from '../globalContext';
const { TextArea } = Input;

const AIGeneration = () => {
  const { sessionFormData, setSessionFormData } = useSessionFormData();
  const [visible, setVisible] = useState(false);
  const [isSecondPage, setIsSecondPage] = useState(false);
  const [showEditPrompt, setShowEditPrompt] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [form] = Form.useForm();
  const [maxCount, setMaxCount] = useState(1);
  const [loading, setLoading] = useState(false); // Loading state

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setIsSecondPage(false);
  };

  const handleEditPromptResponse = (edit) => {
    setShowEditPrompt(false);
    if (edit) {
      setIsSecondPage(true);
      setVisible(true);
    } else {
      sendEmptyRoot();
    }
  };

  const sendEmptyRoot = async () => {
    try {
      const userId = localStorage.getItem('userId');
      await fetch(process.env.REACT_APP_API_ICE_URL + '/gpt/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userId, text: '<root></root>' }),
      });
      message.info('Тексты были отменены.');
    } catch (error) {
      console.error(error);
      message.error('Ошибка при отправке данных.');
    }
  };

  const onFinish = async (values) => {
    const { content } = values;
    const userId = localStorage.getItem('userId');
  
    try {
      await fetch(process.env.REACT_APP_API_ICE_URL + '/gpt/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userId,
          message: content,
          count: maxCount, // Используйте maxCount здесь
        }),
      });
  
      message.success('Запрос успешно отправлен');
  
      // Переходим на вторую страницу виджета
      setIsSecondPage(true);
    } catch (error) {
      console.error(error);
      message.error('Ошибка при генерации текста.');
    }
  };

  useEffect(() => {
    refreshText();
  }, [isSecondPage]);

  useEffect(() => {
    const checkForUneditedTexts = async () => {
        setLoading(true); // Set loading to true when starting to fetch data
        try {
          const userId = localStorage.getItem('userId');
          const response = await fetch(process.env.REACT_APP_API_ICE_URL + '/gpt/download/completion', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: userId }),
          });
          if (response.ok) {
            try {
              const data = await response.text();
              if (data.trim() === "No File") {
                message.warning('Похоже у вас не было запросов на генерацию, отправьте его скорее!');
              } else {
                try {
                  if (data.includes('<root>')) {
                    message.warning('У вас есть не отредактированные тексты.\nХотите их отредактировать?');
                    setShowEditPrompt(true);
                  }
                } catch (error) {
                  console.error(error);
                }
              }
            } catch (error) {
              console.error(error);
            }
          } else {
            console.error(response.statusText);
            message.error('Ошибка при загрузке текста.');
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false); // Set loading to false after fetching is complete
        }
    };

    if (sessionFormData.subscriptionMessage === "") {
      checkForUneditedTexts();
    }
  }, [sessionFormData.subscriptionMessage]);

  const refreshText = async () => {
    if (sessionFormData.subscriptionMessage === "") {
      setLoading(true); // Set loading to true when starting to fetch data
      try {
        const userId = localStorage.getItem('userId');
  
        const response = await fetch(process.env.REACT_APP_API_ICE_URL + '/gpt/download/completion', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: userId }),
        });
  
        if (response.ok) {
          try {
            const data = await response.text();
            if (data.trim() === "No File") {
              message.warning('Похоже у вас не было запросов на генерацию, отправьте его скорее!');
            } else {
              processXmlBlocks(data);
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          console.error(response.statusText);
          message.error('Ошибка при загрузке текста.');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false after fetching is complete
      }
    }
  };
  
  const processXmlBlocks = (xmlContent) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlContent, 'text/xml');
    const xmlBlocks = xmlDoc.getElementsByTagName('block');
  
    const textBlocks = Array.from(xmlBlocks).map((block, index) => ({
      id: index,
      content: block.textContent // Используйте textContent для получения только текста
    }));
  
    setBlocks(textBlocks);
  };
  
  const handleTextChange = (content, id) => {
    const updatedBlocks = blocks.map(block => block.id === id ? { ...block, content } : block);
    setBlocks(updatedBlocks);
  };
  
  const convertTextToXml = () => {
    const filteredBlocks = blocks.filter(block => block.content.trim() !== '');
  
    const xmlString = filteredBlocks.map(block => `<block>${block.content}</block>`).join('');
    return `<root>${xmlString}</root>`; // Предполагая, что ваш корневой элемент - <root>
  };
  
  const handleSaveChanges = async () => {
    try {
      const userId = localStorage.getItem('userId');

      const xmlContent = convertTextToXml();
      message.info('Подождите пару минут, пока нейросеть создаст тексты');
      await fetch(process.env.REACT_APP_API_ICE_URL + '/gpt/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userId, text: xmlContent }),
      });
      
      setVisible(false);
      setIsSecondPage(false);
    } catch (error) {
      console.error(error);
      message.error('Ошибка при сохранении изменений.');
    }

    setVisible(false);
    setIsSecondPage(false);
  };

  return (
    <div>
      {sessionFormData.subscriptionMessage ? 
        <div className="subscription-message" style={{ height: '100vh' }}>{sessionFormData.subscriptionMessage}</div> :
        (<>
          <div className='ai-generation-page' style={{ height: '100vh' }}>
            <Button type="primary" onClick={showModal}>
              Создать сюжет
            </Button>
            <Modal
              title="Генератор AI"
              visible={visible}
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  Отмена
                </Button>,
                !isSecondPage && (
                  <Button key="submit" type="primary" onClick={() => form.submit()}>
                    Далее
                  </Button>
                ),
                isSecondPage && (
                  <Button key="save" type="primary" onClick={handleSaveChanges}>
                    Сохранить изменения
                  </Button>
                ),
              ]}
              style={{ top: 20, minWidth: '50vw', minHeight: 'calc(33vh)' }}
            >
              {loading ? (
                <div style={{ textAlign: 'center' }}>
                  <Spin size="large" />
                  <p>Идет загрузка...</p>
                </div>
              ) : !isSecondPage ? (
                <Form form={form} onFinish={onFinish}>
                  <Form.Item label="Максимальное количество" name={['frequency']}>
                    <Slider id="max_count"
                            min={1}
                            max={100}
                            step={1}
                            onChange={(value) => setMaxCount(value)} />
                  </Form.Item>
                  <Form.Item name="content" label="Введите запрос">
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </Form>
              ) : (
                <div>
                  {blocks.map((block) => (
                    <TextArea
                      key={block.id}
                      value={block.content}
                      onChange={(e) => handleTextChange(e.target.value, block.id)}
                      rows={4}
                    />
                  ))}
                </div>
              )}
            </Modal>
            <Modal
              title="Редактировать тексты?"
              visible={showEditPrompt}
              onOk={() => handleEditPromptResponse(true)}
              onCancel={() => handleEditPromptResponse(false)}
              cancelText="Нет"
              okText="Да"
            >
              <p>У вас есть не отредактированные тексты. Хотите их отредактировать?</p>
            </Modal>
          </div>
        </>)
      }
    </div>
  );
};

export default AIGeneration;
