import React, { useState, useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import moment from 'moment-timezone';
import { useSessionFormData } from '../globalContext';

const { Option } = Select;

const SettingsGeneral = () => {
  const { sessionFormData, setSessionFormData } = useSessionFormData();
  const [timezones] = useState(moment.tz.names().map((tz) => {
    const offset = moment.tz(tz).utcOffset();
    const formattedOffset = `(GMT${offset >= 0 ? '+' : ''}${(offset / 60).toString().padStart(2, '0')}:00)`;
    return { value: tz, label: `${formattedOffset} ${tz}` };
  }));

  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const settingsGeneral = sessionFormData.settings?.general || {};
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024 * 2) {
        alert("Файл слишком большой. Максимальный размер 2Мб");
        return;
      }
      setFile(file);
      const previewUrl = URL.createObjectURL(file);
      setPreviewUrl(previewUrl);
      setSessionFormData(prevData => ({
        ...prevData,
        file: file,
      }));
    } else {
      setFile(null);
      setPreviewUrl(null);
      setSessionFormData(prevData => ({
        ...prevData,
        file: null,
      }));
    }
  };

  const handleFormChange = (changedValues, allValues) => {
    const selectedTimezone = allValues.general?.timezone;

    if (selectedTimezone) {
      const offset = moment.tz(selectedTimezone).utcOffset();
      const formattedOffset = `(GMT${offset >= 0 ? '+' : ''}${(offset / 60).toString().padStart(2, '0')}:00)`;

      setSessionFormData(prevData => {
        const updatedGeneralSettings = {
          station_name: allValues.general?.station_name ?? prevData.settings.general?.station_name,
          station_description: allValues.general?.station_description ?? prevData.settings.general?.station_description,
          timezone: formattedOffset,
          timezone_name: selectedTimezone,
          logo: allValues.general?.logo ?? prevData.settings.general?.logo,
        };

        return {
          ...prevData,
          settings: {
            ...prevData.settings,
            general: updatedGeneralSettings,
          }
        };
      });
    }
  };

  return (
    <Form form={form} onValuesChange={handleFormChange}>
      <div className="general-container">
        <h2 className="form-heading">Основные</h2>

        <label>Название Радио</label><br/>
        <Form.Item 
          className="neuro-settings-form-general"
          initialValue={settingsGeneral.station_name || null} 
          name={['general', 'station_name']} 
          rules={[{ required: true, message: 'Введите название станции' }]}
          style={{ width: '15vw' }}
        >
          <Input />
        </Form.Item>

        <label>Описание станции</label><br/>
        <Form.Item 
          className="neuro-settings-form-general"
          initialValue={settingsGeneral.station_description || null}
          name={['general', 'station_description']}
          style={{ width: '25vw' }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <label>Часовой пояс</label><br/>
        <Form.Item 
          className="neuro-settings-form-general"
          initialValue={settingsGeneral.timezone_name || null}
          name={['general', 'timezone']} 
          rules={[{ required: true, message: 'Выберите часовой пояс' }]}
          style={{ width: '15vw' }}
        >
          <Select
            showSearch
            placeholder="Выберите часовой пояс"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
            id="neuro-settings-form-general-select"
          >
            {timezones.map(({ value, label }) => (
              <Option key={value} value={value} label={label}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <label>Логотип</label><br/>
        <Form.Item 
          name={['general', 'logo']}
          className="neuro-settings-form-general"
          style={{ width: '15vw' }}
        >
          <Input accept={['.jpeg', '.png', '.jpg']} type="file" onChange={handleFileChange} />
        </Form.Item>

        {previewUrl && (
          <div className="logo-preview">
            <h4>Предпросмотр логотипа:</h4>
            <img src={previewUrl} alt="Предпросмотр логотипа" style={{ width: '15vw', height: '15vh' }} />
          </div>
        )}
      </div>
    </Form>
  );
};

export default SettingsGeneral;
