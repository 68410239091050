import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { PlayCircleOutlined, DownOutlined, SettingOutlined, AreaChartOutlined, ApartmentOutlined, EuroOutlined } from '@ant-design/icons';
import Neuro from './components/site/neuro';
import Login from './components/site/login';
import RssNews from './components/content/rssNews';
import AIGeneration from './components/content/aiGeneration';
import OwnAudio from './components/content/ownAudio';
import SettingsForm from './components/settings/settings';
import ScheduleAndStatistic from './components/schedule/schedule';
import Signup from './components/site/signup';
import Subscription from './components/site/subscription';
import HeaderComponent from './components/headerComponent';
import FormDataProvider from './components/globalContext';
import AudioPlayer from './components/site/audioPlayer';
import AuthTokenChecker from './components/site/authTokenChecker';
import SubscriptionChecker from './components/access';

import AdminTable from './components/admin/admin';
import UserTable from './components/admin/users';

import './App.css';

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const AppLayout = ({ isAuthenticated, userRole, collapsed, toggleCollapse }) => {
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);

  const isAdmin = userRole === 'Admin';
  const isSuperAdmin = userRole === 'Superadmin';

  useEffect(() => {
    // Закрываем подменю при изменении маршрута
    setOpenKeys([]);
  }, [location]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys); // Управление состоянием открытия подменю
  };

  return (
    <Layout>
      <HeaderComponent />
      <Layout>
        <Sider
          width={'13vw'}
          theme="dark"
          style={{ backgroundColor: '#001529', color: '#fff' }}
          collapsible
          collapsed={collapsed}
          onCollapse={toggleCollapse}
        >
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['1']}
            selectedKeys={[location.pathname]} // Установка активного элемента меню
            openKeys={openKeys} // Состояние открытых подменю
            onOpenChange={onOpenChange} // Обработка изменения состояния подменю
          >
            {isAdmin && (
              <>
                <Menu.Item key="/neuro" icon={<PlayCircleOutlined />}>
                  <Link to="/neuro">Нейро</Link>
                </Menu.Item>
                <SubMenu
                  key="content"
                  icon={<DownOutlined />}
                  title="Контент"
                  inlineCollapsed={true}
                >
                  <Menu.Item key="/content/rss">
                    <Link to="/content/rss">Новости из RSS ленты</Link>
                  </Menu.Item>
                  <Menu.Item key="/content/ai">
                    <Link to="/content/ai">AI генерация</Link>
                  </Menu.Item>
                  <Menu.Item key="/content/own">
                    <Link to="/content/own">Своё аудио</Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="/settings" icon={<SettingOutlined />}>
                  <Link to="/settings">Настройки</Link>
                </Menu.Item>
                <Menu.Item key="/schedule" icon={<AreaChartOutlined />}>
                  <Link to="/schedule">Статистика и расписание</Link>
                </Menu.Item>
              </>
            )}
            {isSuperAdmin && (
              <>
                <Menu.Item key="/admin/admin" icon={<ApartmentOutlined />}>
                  <Link to="admin/admin">Панель пользователей</Link>
                </Menu.Item>
                <Menu.Item key="/admin/users" icon={<EuroOutlined />}>
                  <Link to="admin/users">Панель подписок</Link>
                </Menu.Item>
              </>
            )}
          </Menu>
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: collapsed ? '4vw' : '8vw', marginRight: collapsed ? '4vw' : '8vw' }}>
          <SubscriptionChecker isMainPage={true} />
          <Content style={{ margin: '1vw' }}>
            <Routes>
              <Route path="/neuro" element={<Neuro />} />
              <Route path="/content/rss" element={<RssNews />} />
              <Route path="/content/ai" element={<AIGeneration />} />
              <Route path="/content/own" element={<OwnAudio />} />
              <Route path="/settings" element={<SettingsForm />} />
              <Route path="/schedule" element={<ScheduleAndStatistic />} />
              <Route path="/site/login" element={<Login />} />
              <Route path="/site/signup" element={<Signup />} />
              <Route path="/site/subscription" element={<Subscription />} />
              <Route path="/neuro/player" element={<AudioPlayer />} />
              {isSuperAdmin && (
                <>
                  <Route path="/admin/admin" element={<AdminTable />} />
                  <Route path="/admin/users" element={<UserTable />} />
                </>
              )}
              <Route path="/" element={<Navigate to="/neuro" />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    if (token && token !== "undefined" && role) {
      setIsAuthenticated(true);
      setUserRole(role);
    }
  }, []);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <FormDataProvider>
      <AuthTokenChecker>
        <Router>
          {!isAuthenticated ? (
            <Routes>
              <Route path="/site/login" element={<Login />} />
              <Route path="/site/signup" element={<Signup />} />
              <Route path="/" element={<Navigate to="/site/login" />} />
            </Routes>
          ) : (
            <AppLayout
              isAuthenticated={isAuthenticated}
              userRole={userRole}
              collapsed={collapsed}
              toggleCollapse={toggleCollapse}
            />
          )}
        </Router>
      </AuthTokenChecker>
    </FormDataProvider>
  );
};

export default App;
