import React, { useContext, useEffect, useState } from 'react';
import { Form, Slider, Select, Button, Space } from 'antd';
import {PlayCircleOutlined} from '@ant-design/icons'
import { authToken } from './settings';
import axios from 'axios';
import { useSessionFormData } from '../globalContext';

const { Option } = Select;

const SettingsOthers = () => {
  const { sessionFormData, setSessionFormData } = useSessionFormData();
  const [voices, setVoices] = useState([]);
  const [form] = Form.useForm();
  const [currentAudio, setCurrentAudio] = useState(null);

  const handleFormChange = (changedValues, allValues) => {
    //if(sessionFormData.subscriptionMessage === ""){
      setSessionFormData(prevData => {
        const updatedOthersSettings = {
          frequency: allValues.others?.frequency ?? allValues?.frequency,
          voices: {
            rss_voice: allValues.others?.voices?.rss_voice ?? allValues.voices?.rss_voice,
            jingle_voice: allValues.others?.voices?.jingle_voice ?? allValues.voices?.jingle_voice,
            gpt_voice: allValues.others?.voices?.gpt_voice ?? allValues.voices?.gpt_voice,
          }
        };
        
        return {
          ...prevData,
          settings: {
            ...prevData.settings,
            others: updatedOthersSettings
          }
        };
      });
    //}
  };

  useEffect(() => {
    if (sessionFormData.subscriptionMessage === "") {
      axios.get(process.env.REACT_APP_API_URL + '/settings/get-voices-list', {
        headers: {
          'Authorization': `Bearer ${authToken}`, // Include the token in the request headers
        },
      })
        .then(response => {
          const parsedData = JSON.parse(response.data);
          setVoices(parsedData);
        })
        .catch(error => {
          console.error('Error fetching voices list:', error);
        });
    }
  }, [sessionFormData.subscriptionMessage]);
  const playAudio = (filename) => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }

    const newAudio = new Audio(`/${filename}.wav`);
    setCurrentAudio(newAudio);
    newAudio.play();
  };
  return (
    <div>
      {sessionFormData.subscriptionMessage ? <div className="subscription-message">{sessionFormData.subscriptionMessage}</div> : (
        <Form form={form} onValuesChange={handleFormChange}>
          <div className="neuro-settings-form-others">
            <h2>Прочее</h2>
            <label>Частота новостных вставок</label><br />
            <Form.Item initialValue={sessionFormData.settings?.others?.frequency} name={['frequency']}>
              <Slider id="frequency" style={
                { 
                  width: '25vw', 
                }
                } min={1} max={10} step={1} />
            </Form.Item>
              <h2>Ведущий</h2>
              <label>Голос для RSS</label>
              <Form.Item>
              <Space>
              <Form.Item name={['voices', 'rss_voice']} initialValue={sessionFormData.settings?.others?.voices?.rss_voice ? sessionFormData.settings?.others?.voices?.rss_voice : null} >
                <Select id="rss_voice" placeholder='Выберите голос'>
                  {Object.entries(voices).map(([key, value]) => (
                    <Option key={value} value={value}>{key}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
              <Button name={['voices', 'rss_voice', 'button']} onClick={() => playAudio(sessionFormData.settings?.others?.voices?.rss_voice)}><PlayCircleOutlined /></Button>
              </Form.Item>
              </Space>
              </Form.Item>
              <label>Голос для отбивок</label>
              <Form.Item>
                <Space>
                <Form.Item name={['voices', 'jingle_voice']} initialValue={sessionFormData.settings?.others?.voices?.jingle_voice ? sessionFormData.settings?.others?.voices?.jingle_voice : null} >
                <Select id="jingle_voice" placeholder='Выберите голос'>
                  {Object.entries(voices).map(([key, value]) => (
                    <Option key={value} value={value}>{key}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
              <Button name={['voices', 'jingle_voice', 'button']} onClick={() => playAudio(sessionFormData.settings?.others?.voices?.jingle_voice)}><PlayCircleOutlined /></Button>
              </Form.Item>
                </Space>
              </Form.Item>
              <label>Голос для AI</label>
              <Form.Item>
                <Space>
                <Form.Item name={['voices', 'gpt_voice']} initialValue={sessionFormData.settings?.others?.voices?.gpt_voice ? sessionFormData.settings?.others?.voices?.gpt_voice : null} >
                <Select id="gpt_voice" placeholder='Выберите голос'>
                  {Object.entries(voices).map(([key, value]) => (
                    <Option key={value} value={value}>{key}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
              <Button name={['voices', 'gpt_voice', 'button']} onClick={() => playAudio(sessionFormData.settings?.others?.voices?.gpt_voice)}><PlayCircleOutlined /></Button>
              </Form.Item>
                </Space>
              </Form.Item>
          </div>
        </Form>
      )}
    </div>
  );
};

export default SettingsOthers;