import React, { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'
import axios from 'axios';

const AuthTokenChecker = ({ children }) => {
    useEffect(() => {
        checkAndRefreshToken();
        // Установите интервал для периодической проверки
        checkAndRefreshToken();
        const interval = setInterval(checkAndRefreshToken, 60 * 60 * 1000); // каждые 5 минут

        return () => clearInterval(interval);
    }, []);

    const checkAndRefreshToken = async () => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!token || isTokenExpired(token)) {
            await refreshTokenRequest(token);
        }
    };

    const isTokenExpired = (token) => {
        try {
            const decoded = jwtDecode(token);
            const timeFromUnix = new Date(decoded.exp * 1000);
            return timeFromUnix < new Date();
        } catch (error) {
            console.error('Ошибка при декодировании JWT:', error);
        }
    };

    const refreshTokenRequest = async (refreshToken) => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/site/refresh-token', {token: refreshToken});
            console.log(response);
            localStorage.setItem('token', response.data.token);
        } catch (error) {
            console.error('Ошибка при обновлении токена:', error);
            // Обработка ошибок, возможно редирект на страницу входа
        }
    };

    return <>{children}</>;
};

export default AuthTokenChecker;
