import React, { useState, useEffect } from 'react';
import { useSessionFormData } from '../globalContext';
import { Card, Input, Button, Alert, Modal } from 'antd';

const Subscription = () => {
    const [planList, setPlanList] = useState([]);
    const [prices, setPrices] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [price, setPrice] = useState('');
    const [email, setEmail] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [confirmationToken, setConfirmationToken] = useState('');
    const [returnUrl, setReturnUrl] = useState('');
    const [isWidgetInitialized, setIsWidgetInitialized] = useState(false);
    const [isCompany, setIsCompany] = useState(false);
    const { sessionFormData, setSessionFormData } = useSessionFormData();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        // Load plan list and prices (replace this with actual data fetching)

        const fetchCompany = async () => {
            try{
                const response = await fetch(process.env.REACT_APP_API_URL+'/user/is-company', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                    body: JSON.stringify({id: localStorage.getItem('userId')})
                })
                const data = await response.json();
                console.log(data);
                setIsCompany(data.is_company);
            } catch (error){
                console.log(error);
            }
        };

        const fetchPlanList = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/sale/plan-list', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    }
                }); // Replace with your actual API endpoint
                const data = await response.json();
                setPlanList(data);

            } catch (error) {
                console.error('Failed to fetch plan list:', error);
                // Handle error appropriately
            }
        };
        const fetchPriceList = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/sale/price-list', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    }
                }); // Replace with your actual API endpoint
                const data = await response.json();
                setPrices(data);

            } catch (error) {
                console.error('Failed to fetch plan list:', error);
                // Handle error appropriately
            }
        };
        fetchCompany();
        fetchPlanList();
        fetchPriceList();
        const script = document.createElement('script');
        script.src = "https://yookassa.ru/checkout-widget/v1/checkout-widget.js";
        script.async = false;

        document.body.appendChild(script);

    }, []);

    const handleCardClick = value => {
        setSelectedPlan(value);
        const newPrice = prices[value] || 'Unavailable';
        setPrice(newPrice);
    };

    useEffect(() => {
        if (isModalVisible && !isWidgetInitialized && confirmationToken) {
            initWidget(confirmationToken, returnUrl);
            setIsWidgetInitialized(true); // Помечаем, что виджет инициализирован
        }
    }, [isModalVisible, confirmationToken, returnUrl, isWidgetInitialized]);


    const handleSubmit = async e => {
        e.preventDefault();
        setShowSuccess(false);
        setShowError(false);

        // Replace with your endpoint and request structure
        const response = await fetch(process.env.REACT_APP_API_URL + '/sale/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            body: JSON.stringify({
                plan: selectedPlan,
                sum: price,
                user_id: email,
            }),
        });

        const data = await response.json();
        if (data.success) {
            setConfirmationToken(data.data.confirmation.confirmation_token);
            setReturnUrl(process.env.REACT_APP_API_URL + '/sale/confirm?sum=' +data.callback_data.sum + '&plan=' +data.callback_data.plan+'&email='+localStorage.getItem('userId'));
            setIsWidgetInitialized(false);
            showModal();
        } else {
            // Handle failure
            setShowError(true);
            setErrorMessage('Error message here'); // Replace with actual error message
        }
    };

    const checkSubscription = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_API_URL + '/user/get-subscription', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({ id: localStorage.getItem('userId') }),
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const subscription = await response.json();
          console.log(subscription);
          const subscriptionEnd = new Date(subscription.ends_at);
          const now = new Date();
    
          let newMessage = '';
          if (subscription.name === 'trial') {
              newMessage = 'У вас нет подписки на сервис, доступен лишь базовый функционал! Пожалуйста приобретите подписку.';
          } else if (subscriptionEnd < now) {
              newMessage = 'Ваша подписка истекла, пожалуйста оформите её ещё раз!';
          }
          setSessionFormData(prevFormData => ({
              ...prevFormData,
              subscriptionMessage: newMessage
            }));
    
        } catch (error) {
          console.error('There was an error checking the subscription:', error);
        }
      };

    const initWidget = function (confirmationToken, returnUrl) {
        const checkout = new window.YooMoneyCheckoutWidget({
            confirmation_token: confirmationToken,
            //return_url: returnUrl, 
            error_callback(error) {
                
            },
            customization: {
                //Настройка способа отображения
                modal: true
            },
        });
        checkout.on('success', () => {
            fetch(returnUrl, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            }).then((response) => response.json())
            .then((data) => {
                // Проверяем успешность платежа и обновляем состояние
                if (data.success) {
                    setIsModalVisible(false);
                    setShowSuccess(true);
                    setShowError(false);
                    checkSubscription();
                } else {
                    setShowSuccess(false);
                    setShowError(true);
                    setErrorMessage('Ошибка при подтверждении платежа');
                }
            }).catch((error) => {
                // Обработка ошибок запроса
                setShowError(true);
                setErrorMessage('Ошибка сети: ' + error.message);
            }).finally(() => {
                checkout.destroy();
            });
        });
        checkout.on('modal_close', () => {
            //Код, который нужно выполнить после закрытия всплывающего окна.
            checkout.destroy();
        });
        checkout.render('payment-form').then(() => {

        });
    }

    return (
        <div className="card-block" style={{ height: '100vh' }}>
            {isCompany ? (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h2>Для оплаты от юридического лица пожалуйста обратитесь к нашим менеджерам и мы выставим вам счет.</h2>
                </div>
            ) : (
            <form onSubmit={handleSubmit}>
                <div id='payment-form'></div>
                <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                    {Object.entries(planList).map(([value, label]) => (
                        <Card
                            key={value}
                            title={label}
                            style={{ 
                                height: 'calc(66.66% - 10px)',
                                width: 'calc(33.33% - 10px)', 
                                cursor: 'pointer',
                                border: selectedPlan === value ? '2px solid blue' : '1px solid grey', 
                                marginBottom: '10px',
                            }}
                            onClick={() => handleCardClick(value)}
                        >
                            <p>Цена: {prices[value]}</p>
                            <p>Доступные функции: </p>
                            <p>- Автоматический парсинг RSS формата Yandex-Turbo-Page; </p>
                            <p>- Доступ к генерации текстов по запросу через GPT;</p>
                            <p>- Возможность загружать собственные треки в очередь;</p>
                            <p>- Возможность создать собственную отбивку для проигрывания по времени/через треки.</p>
                        </Card>
                    ))}
                </div>
                <br />
                <label>Email для чека</label> <br/>
                <Input value={email} onChange={e => setEmail(e.target.value)} style={{padding: '40hv', width: '20%'}} />
                <br /><br />
                <Button type="primary" htmlType="submit" style={{alignSelf: 'flex-end'}}>
                    Перейти к оплате
                </Button>
                {showSuccess && <Alert message="Оплата прошла успешно!" type="success" />}
                {showError && <Alert message={errorMessage} type="error" />}
            </form>)}
            
        </div>
    );
};

export default Subscription;