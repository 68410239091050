import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Select, Input } from 'antd';
import axios from 'axios';

const UserTable = () => {
    const [data, setData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [searchText, setSearchText] = useState('');
  
  
  useEffect(() => {
    const fetchUsers = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL+'/user/get-users',{
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }); 
            if (response.data && response.data.length > 0) {
                setData(response.data[0]);
            }
          } catch (error) {
            console.error('Ошибка при получении данных:', error);
          }
      };
  
      const fetchSubscriptionPlans = async () => {
        try {
          const response = await axios.get(process.env.REACT_APP_API_URL + '/sale/plan-list', {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
          });
          console.log("Планы подписки:", response.data); // Добавьте это для проверки
          setSubscriptionPlans(response.data);
        } catch (error) {
          console.error('Ошибка при получении списка планов подписки:', error);
        }
      };
  
      fetchUsers();
      fetchSubscriptionPlans();
  }, []);

  const showEditModal = user => {
    setCurrentUser(user);
    setSelectedPlan(user.subscription_plan);
    setIsModalVisible(true);
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter(item => {
    return Object.keys(item).some(key =>
      item[key] !== null && item[key] !== undefined && 
      item[key].toString().toLowerCase().includes(searchText.toLowerCase())
    );
  });
  

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/sale/update-plan?userId='+currentUser.email + '&newPlan='+selectedPlan , /*{
        userId: currentUser.email,
        newPlan: selectedPlan,
      },*/ {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.data.success) {
        // Обновите данные пользователя в таблице, если это необходимо
      } else {
        // Обработка ошибки
      }
    } catch (error) {
      console.error('Ошибка при обновлении плана подписки:', error);
    }
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Имя пользователя',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Почта',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'План подписки',
      dataIndex: 'subscription_string',
      key: 'subscription_plan',
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => showEditModal(record)}>Изменить план</Button>
      ),
    },
  ];

  return (
    <>
      <Input placeholder="Поиск..." onChange={handleSearch} style={{ marginBottom: 8, display: 'block' }} />
      <Table columns={columns} dataSource={filteredData} />
      <Modal
        title="Изменение плана подписки"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form>
          <Form.Item label="Выберите новый план">
          <Select 
            defaultValue={currentUser ? currentUser.subscription_plan : ''}
            onChange={setSelectedPlan}
            >
            {Object.entries(subscriptionPlans).map(([planId, planName]) => (
                <Select.Option key={planId} value={planId}>{planName}</Select.Option>
            ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UserTable;
